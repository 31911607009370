body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(55deg, #dd8780, #a293aa, #6c9bcc, #435e7a);
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.content-container {
  padding-bottom: 112px;
  @media only screen and (max-width: 500px) {
    padding-bottom: 150px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
